<template>
  <div id="header-container">
    <div class="inner">
      <div class="icons">
        <div class="icon">
          <a href="https://twitter.com/welcomevillain" target="_blank">
            <inline-svg :src="require('@/assets/images/global/twitter.svg')"/>
          </a>
        </div>
        <div class="icon">
          <a href="https://www.facebook.com/WelcomeVillain/" target="_blank">
            <inline-svg :src="require('@/assets/images/global/facebook-f.svg')"/>
          </a>
        </div>
        <div class="icon">
          <a href="https://www.instagram.com/welcomevillain/" target="_blank">
            <inline-svg :src="require('@/assets/images/global/instagram.svg')"/>
          </a>
        </div>
        <div class="icon">
          <a href="https://www.youtube.com/channel/UCDteF1bU5KPODkda_0j_VbA?sub_confirmation=1" target="_blank">
            <inline-svg :src="require('@/assets/images/global/youtube.svg')"/>
          </a>
        </div>
        <!--
        <div class="icon">
          <a href="https://google.com" target="_blank">
            <inline-svg :src="require('@/assets/images/global/snapchat.svg')"/>
          </a>
        </div>
        -->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderFramework"
}
</script>