<template>
    <section id="section-home">
        <div id="main-bg-image" class="bgimg bg-animate-hook" :style="`background-image: url(` + require('@/assets/images/home/bg.jpg') + `)`"></div>
        <div id="corner-l" class="bg-animate-hook" :style="`background-image: url(` + require('@/assets/images/home/corner-l.png') + `)`"></div>
        <div id="corner-r" class="bg-animate-hook" :style="`background-image: url(` + require('@/assets/images/home/corner-r.png') + `)`"></div>
        <div class="panel-tt">
            <div class="panel-tt2">
                <img src="@/assets/images/home/sidegraphic.png"/>
            </div>
        </div>
        <div class="panel-outer">
            <div class="panel">
                <div class="panel2">
                    <!--
                    <div class="l1 style1 stagger1">From the director of ANNABELLE</div>

                    <div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>
                    -->
                    <!--
                    <div class="topdate1 stagger1">

                    </div>

                  -->

                    <div v-if="dateMode===1" class="topdate2a stagger1">
                        IN SELECT THEATERS
                    </div>
                    <div v-if="dateMode===1" class="topdate2 stagger1">
                        SEPTEMBER 1
                    </div>

                    <div v-if="dateMode===2" class="topdate3 stagger1">
                        <img alt="NOW IN SELECT THEATERS" src="@/assets/images/now-in-select-theaters.png"/>
                    </div>
                    <div v-if="dateMode===3" class="topdate2 stagger1">
                        PREORDER NOW
                    </div>

                    <div v-if="dateMode===4" class="topdate3 stagger1">
                        <img alt="NOW AVAILABLE ON DIGITAL" src="@/assets/images/now-on-digital.png" />
                    </div>
                    <div v-if="dateMode===4" class="gettix2 stagger1">
                        <div class="l-small">
                            <button class="btn" @click="openAction">
                                <span>WATCH NOW</span>
                            </button>
                        </div>
                    </div>

                    <div class="stagger-bugs stagger1" v-if="dateMode == 2">
                        <button class="bug">
                            <div class="bug2">
                                <a href="https://www.amctheatres.com/movies/beaten-to-death-72487" target="_blank">
                                    <img src="@/assets/images/order/amc.png"/>
                                </a>
                            </div>

                        </button>
                        <button class="bug">
                            <div class="bug2">
                                <a href="https://www.fandango.com/beaten-to-death-2023-232849/movie-overview" target="_blank">
                                    <img src="@/assets/images/order/fandango.png"/>
                                </a>
                            </div>

                        </button>
                        <button class="bug">
                            <div class="bug2">
                                <a href="https://www.movietickets.com/movie/beaten-to-death/d22eea7b-af42-459c-8c1f-ff74ea76e227" target="_blank">
                                    <img src="@/assets/images/order/movie-tickets.png"/>
                                </a>
                            </div>
                        </button>
                        <button class="bug wide">
                            <div class="bug2">
                                <a href="https://www.regmovies.com/movies/beaten-to-death/ho00014899#/buy-tickets-by-film-for-cinemas?at=2023-09-01&for-movie=ho00014899&view-mode=list" target="_blank">
                                    <img src="@/assets/images/order/regal.png"/>
                                </a>
                            </div>
                        </button>
                    </div>

                    <div class="stagger-bugs big stagger1" v-if="dateMode == 3">
                        <button class="bug">
                            <div class="bug2">
                                <a href=" https://www.amctheatres.com/movies/beaten-to-death-72487" target="_blank">
                                    <img src="@/assets/images/order/apple.png"/>
                                </a>
                            </div>

                        </button>
                    </div>


                    <!--
                    <div class="gettix stagger1">
                        <div class="l-small">
                            <button @click="openAction">
                                <span v-if="dateMode!==3">GET TICKETS</span>
                                <span v-if="dateMode===3">WATCH NOW</span>
                            </button>
                        </div>
                    </div>
                    -->

                    <button :class="['ytplayer stagger1',(noVideo) ? 'novideo' : '']" @click="openVideo">
                        <div class="bg">
                            <img src="@/assets/images/home/player.jpg"/>
                        </div>
                        <div class="icon stagger1">
                            <div class="icon2">
                                <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
                            </div>
                        </div>
                        <div v-if="noVideo" class="comingsoon">
                            <div class="comingsoon2" v-html="trailerOverlayMessage">
                            </div>
                        </div>
                    </button>

                    <div class="l3 style2 signup stagger1">SIGN UP FOR UPDATES</div>
                    <div class="signup-form stagger1">
                        <EmailSignup
                            mcDomain="https://welcomevillain.us20.list-manage.com"
                            mcUserId="b25b645d846865015940afb4d"
                            mcListId="f713ffe268"
                        ></EmailSignup>
                    </div>

                    <div class="btn-outer stagger1">
                        <a href="https://docs.google.com/document/d/1IrYwIbS6p-LcqS3ZQMr-YXB6zbqKv1EnOSDqqmkoHCU/edit?usp=sharing" target="_blank" rel="noreferrer" >
                            <button class="btn">
                                <span>PRESS/EPK</span>
                            </button>

                        </a>
                    </div>

                    <!--
                  <div class="hashtag stagger1">#LilithIsHere</div>
                  -->
                </div>
            </div>
        </div>
        <div v-if="dateMode == 4" id="order" :class="[(isOpen) ? 'open' : '']">
            <div @click="closeAction" class="scrim-closer"></div>
            <div class="order-inner">
                <div class="order-inner2">
                    <div class="tt">
                        <img src="@/assets/images/popup/popup_01.png"/>
                    </div>
                    <div class="bugs-bg">
                        <div class="bugs-bg2" :style="`background-image: url(` + require('@/assets/images/popup/popup_02.png') + `)`"></div>
                        <div class="bugs">
                            <button class="bug">
                                <a href="https://www.amazon.com/gp/video/detail/B0CG8XW932/ref=atv_dp_share_cu_r"
                                   target="_blank">
                                    <img src="@/assets/images/order/amazon.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://play.google.com/store/movies/details/Beaten_to_Death?id=p1JF3R2j7iw.P&hl=en_US&gl=US"
                                   target="_blank">
                                    <img src="@/assets/images/order/google.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.vudu.com/content/browse/details/Beaten-to-Death/2479113" target="_blank">
                                    <img src="@/assets/images/order/vudu.png"/>
                                </a>
                            </button>

                            <button class="bug">
                                <a href="https://itunes.apple.com/us/movie/beaten-to-death/id1705118535" target="_blank">
                                    <img src="@/assets/images/order/apple.png"/>
                                </a>
                            </button>

                            <!--
                            <button class="bug">
                                <a href="https://tv.apple.com/movie/hunt-her-kill-her/umc.cmc.65mip7ogttx47v7ksexcrz1q1?itsct=tv_box_link&itscg=30200" target="_blank">
                                    <img src="@/assets/images/order/apple-tv-ca.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://tv.apple.com/movie/hunt-her-kill-her/umc.cmc.65mip7ogttx47v7ksexcrz1q1?itsct=tv_box_link&itscg=30200" target="_blank">
                                    <img src="@/assets/images/order/apple-tv.svg"/>
                                </a>
                            </button>


                            <button class="bug">
                                <a href="https://www.regmovies.com/movies/hunt-her-kill-her/ho00013977#/" target="_blank">
                                    <img src="@/assets/images/order/regal.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.amctheatres.com/movies/hunt-her-kill-her-71905" target="_blank">
                                    <img src="@/assets/images/order/amc.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.fandango.com/hunt-her-kill-her-2023-231039/movie-overview?date=2023-03-02"
                                   target="_blank">
                                    <img src="@/assets/images/order/fandango.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.laemmle.com/film/hunt-her-kill-her#get-tickets"
                                   target="_blank">
                                    <img src="@/assets/images/order/lamelle.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.readingcinemasus.com/rohnertpark/film/hunt-her-kill-her"
                                   target="_blank">
                                    <img src="@/assets/images/order/reading.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.consolidatedtheatres.com/kaahumanu"
                                   target="_blank">
                                    <img src="@/assets/images/order/consolidated.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href=" https://lumierecinemala.com/film-hunt-her-kill-her/"
                                   target="_blank">
                                    <img src="@/assets/images/order/lumiere.png"/>
                                </a>
                            </button>
                            -->
                        </div>
                    </div>
                    <div class="tt">
                        <img src="@/assets/images/popup/popup_03.jpg"/>
                    </div>

                </div>
            </div>
            <div @click="closeAction" class="close-button-outer">
                <div class="close-button"></div>
            </div>
        </div>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
import EmailSignup from '@/components/blocks/EmailSignup'

export default {
    name: "HomePage",
    components: {
        EmailSignup
    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
            dateMode:1,
            noVideo:true,
            trailerOverlayMessage:''
        }
    },
    mounted() {
        this.dateCodedLogic()
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    methods: {
        openVideo() {
            if (!this.noVideo) {
                this.gh.openOverlay('video')
            }
        },
        escapeButtonPressed(e) {
            if (e.key === "Escape") {
                this.closeAction()
            }
        },
        openAction() {
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        },

        dateCodedLogic() {
            const now = new Date().getTime()
            // const now = new Date("August 16, 2023 1:00:00 GMT-0700").getTime()
            const firstDate = new Date("August 21, 2023 0:00:00 GMT-0700").getTime()
            const secondDate = new Date("August 22, 2023 0:00:00 GMT-0700").getTime()
            const difference1 = firstDate - now
            const difference2 = secondDate - now
            this.trailerOverlayMessage = 'Trailer Coming Soon'

            if (difference1<0) {
                this.trailerOverlayMessage = 'Trailer Coming Tomorrow'
            }
            if (difference2<0) {
                this.noVideo = false
            }


            // Set the countdown date and time
            const nowPlaying = new Date("September 1, 2023 00:00:00 GMT-0700").getTime()
            if (nowPlaying - now < 0) {
                this.dateMode = 2
            }

            // Set the countdown date and time
            const preOrder = new Date("September 15, 2023 07:00:00 GMT-0700").getTime()
            if (preOrder - now < 0) {
                this.dateMode = 3
            }

            // Get the URL query string
            const queryString = this.window.location.search
            const searchParams = new URLSearchParams(queryString)
            const preview = searchParams.get('preview')
            if (preview) {
                this.dateMode = 4
            }
            this.dateMode = 4
        }
    }
}
</script>